export const LISTING_STATUS = {
  SOLD: 'sold',
  LEASED: 'leased',
}

export const LISTING_TYPE = {
  SALE: 'sale',
  LEASE: 'lease',
}

export const PROPERTY_CLASS = {
  RESIDENTIAL: 'residential',
  COMMERCIAL: 'commercial',
  PROJECT: 'project',
}

export const PROPERTY_RESULTS_PATH = {
  sale: '/for-sale/properties-sale/',
  auction: '/for-sale/auctions/',
  saleInspections: '/for-sale/open-for-inspections/',
  lease: '/rental-properties/properties-rent/',
  leaseInspections: '/rental-properties/open-for-inspections/',
  sold: '/sold-properties/',
  commercialSale: '/for-sale/commercial-properties-sale/',
}

export const PROPERTY_SEARCH = {
  LISTING_TYPE_FIELD: {
    sale: {
      value: 'sale',
      desktop: 'For Sale',
      mobile: 'Buy',
    },
    lease: {
      value: 'lease',
      desktop: 'For Lease',
      mobile: 'Lease',
    },
    sold: {
      value: 'sold',
      desktop: 'Sold Results',
      mobile: 'Sold',
    },
  },
}

export const PROPERTY_FEATURE = {
  alarmSystem: 'Alarm System',
  balcony: 'Balcony',
  broadband: 'Broadband',
  builtInRobes: 'Built-in Robes',
  courtyard: 'Courtyard',
  deck: 'Deck',
  dishwasher: 'Dishwasher',
  floorboards: 'Floorboards',
  furnished: 'Furnished',
  fullyFenced: 'Fully Fenced',
  gym: 'Gym',
  hotWaterService: 'Hot Water Service',
  intercom: 'Intercom',
  outdoorEnt: 'Outdoor Entertainment',
  payTV: 'Pay-TV',
  poolInGround: 'Inground Pool',
  poolAboveGround: 'Above Ground Pool',
  remoteGarage: 'Garage with Remote',
  rumpusRoom: 'Rumpus Room',
  secureParking: 'Secure Parking',
  shed: 'Shed',
  insideSpa: 'Spa (inside)',
  outsideSpa: 'Spa (outside)',
  tennisCourt: 'Tennis Court',
  vacuumSystem: 'Vacuum System',
  workshop: 'Workshop',
  airConditioning: 'Air Conditioning',
  ductedCooling: 'Ducted Cooling',
  ductedHeating: 'Ducted Heating',
  evaporativeCooling: 'Evaporative Cooling',
  gasHeating: 'Gas Heating',
  hydronicHeating: 'Hydronic Heating',
  openFirePlace: 'Open Fire Place',
  reverseCycleAirCon: 'Reverse Cycle Air Conditioning',
  splitSystemAirCon: 'Split System Air Conditioning',
  splitSystemHeating: 'Split System Heating',
  greyWaterSystem: 'Grey water system',
  solarHotWater: 'Solar hot water',
  solarPanels: 'Solar panels',
  waterTank: 'Water tank',
  fullyFenced: 'Fully Fenced',
  ensuite: 'Ensuite',
  openSpaces: 'Open Spaces',
  toilet: 'Toilet',
  garages: 'Garages',
  carports: 'Carports',
  remoteGarage: 'Remote Garage',
  secureParking: 'Secure Parking',
  study: 'Study',
}
