export const listingType = {
  name: 'listing_type',
  label: 'Alert Type',
  options: [
    {
      value: 'sale',
      label: 'Buy',
    },
    {
      value: 'lease',
      label: 'Rent',
    },
    {
      value: 'sold',
      label: 'Sold',
    },
  ],
}

export const query = {
  name: 'query',
  label: 'Suburb, postcode or region',
  isSearch: true,
  multiple: true,
  options: [],
  optgroups: [
    //! these are currently hidden in css
    { value: 'suburbs', label: 'Suburbs' },
    { value: 'regions', label: 'Region' },
    { value: 'postcodes', label: 'Postcodes' },
    { value: 'properties', label: 'Properties' },
  ],
  optgroupField: 'class',
  delimiter: ',',
}

export const propertyClass = {
  name: 'property_class',
  label: 'Classification',
  options: [
    {
      value: 'residential',
      label: 'Residential',
    },
    {
      value: 'commercial',
      label: 'Commercial',
    },
  ],
}

export const propertyType = {
  name: 'categories',
  label: 'Property Type',
  multiple: true,
  options: [
    {
      value: '',
      label: 'All Property Types',
      Classification: '',
      order: 1,
    },
    // key property types
    {
      value: 'House',
      label: 'House',
      Classification: 'residential;holidayRental',
      order: 2,
    },
    {
      value: 'Apartment',
      label: 'Apartment',
      Classification: 'residential;holidayRental',
      order: 3,
    },
    {
      value: 'Unit',
      label: 'Unit',
      Classification: 'residential;holidayRental',
      order: 4,
    },
    {
      value: 'Townhouse',
      label: 'Townhouse',
      Classification: 'residential;holidayRental',
      order: 5,
    },
    {
      value: 'Villa',
      label: 'Villa',
      Classification: 'residential;holidayRental',
      order: 6,
    },
    {
      value: 'Land',
      label: 'Land',
      Classification: 'residential;holidayRental;land',
      order: 7,
    },
    {
      value: 'AcreageSemi-rural',
      label: 'Acreage/Semi-Rural',
      Classification: 'residential;holidayRental',
      order: 8,
    },
    {
      value: 'Flat',
      label: 'Flat',
      Classification: 'residential;holidayRental',
      order: 9,
    },
    {
      value: 'Terrace',
      label: 'Terrace',
      Classification: 'residential;holidayRental',
      order: 10,
    },
    {
      value: 'BlockOfUnits',
      label: 'Apartment Block',
      Classification: 'residential;holidayRental',
      order: 11,
    },
    {
      value: 'ServicedApartment',
      label: 'Serviced Apartment',
      Classification: 'residential;holidayRental',
      order: 12,
    },
    {
      value: 'DuplexSemi-detached',
      label: 'Duplex/Semi-Detached',
      Classification: 'residential;holidayRental',
      order: 13,
    },
    {
      value: 'house-and-land-package',
      label: 'House and Land Package',
      Classification: 'holidayRental',
      order: 14,
    },
    {
      value: 'multiple-houses',
      label: 'Multiple Houses',
      Classification: 'holidayRental',
      order: 15,
    },
    {
      value: 'new-land-sub-division',
      label: 'New Land Sub division',
      Classification: 'holidayRental',
      order: 16,
    },
    {
      value: 'Studio',
      label: 'Studio Apartment',
      Classification: 'residential;holidayRental',
      order: 17,
    },
    {
      value: 'Commercial Farming',
      label: 'Commercial Farming',
      Classification: 'commercial',
      order: 18,
    },
    {
      value: 'Hotel/Leisure',
      label: 'Hotel/Leisure',
      Classification: 'commercial',
      order: 19,
    },
    {
      value: 'Industrial/Warehouse',
      label: 'Industrial/Warehouse',
      Classification: 'commercial',
      order: 20,
    },
    {
      value: 'Land',
      label: 'Vacant Land',
      Classification: 'commercial',
      order: 21,
    },
    {
      value: 'Land/Development',
      label: 'Land/Development',
      Classification: 'commercial',
      order: 22,
    },
    {
      value: 'Medical/Consulting',
      label: 'Medical/Consulting',
      Classification: 'commercial',
      order: 23,
    },
    {
      value: 'Offices',
      label: 'Offices',
      Classification: 'commercial',
      order: 24,
    },
    {
      value: 'Retail',
      label: 'Retail',
      Classification: 'commercial',
      order: 25,
    },
    {
      value: 'Showrooms/Bulky Goods',
      label: 'Showrooms/Bulky Goods',
      Classification: 'commercial',
      order: 26,
    },
    {
      value: 'Lifestyle',
      label: 'Lifestyle',
      Classification: 'rural',
      order: 27,
    },
    {
      value: 'Retirement',
      label: 'Retirement',
      Classification: 'rural',
      order: 28,
    },
    { value: 'Alpine', label: 'Alpine', Classification: 'rural', order: 29 },
    {
      value: 'Other',
      label: 'Other',
      Classification: 'residential;holidayRental;commercial',
      order: 30,
    },
    // all other property types
  ],
}

export const propertyCategory = {
  residential: {
    options: [
      {
        value: '',
        label: 'All Residential',
      },
      {
        value: 'House',
        label: 'House',
      },
      {
        value: 'Apartment,Unit',
        label: 'Apartment/ Unit',
      },
      {
        value: 'BlockOfUnits',
        label: 'Unit Block',
      },
      {
        value: 'Townhouse',
        label: 'Townhouse',
      },
      {
        value: 'Land',
        label: 'Land',
      },
      {
        value: 'Rural',
        label: 'Rural',
      },
      {
        value: 'Acreage',
        label: 'Acreage',
      },
    ],
  },
  commercial: {
    options: [
      {
        value: '',
        label: 'All Commercial',
      },
      {
        value: 'Commercial Farming',
        label: 'Farming',
      },
      {
        value: 'Hotel/Leisure',
        label: 'Hotel/Leisure',
      },
      {
        value: 'Industrial/Warehouse',
        label: 'Industrial/Warehouse',
      },
      {
        value: 'Land/Development',
        label: 'Land/Development',
      },
      {
        value: 'Medical/Consulting',
        label: 'Medical/Consulting',
      },
      {
        value: 'Offices',
        label: 'Offices',
      },
      {
        value: 'Retail',
        label: 'Retail',
      },
      {
        value: 'Showroom/Bulky Goods',
        label: 'Showroom/Bulky Goods',
      },
    ],
  },
}

export const searchBounds = {
  name: 'surrounds',
  label: 'Surrounds',
  options: [
    { value: '', label: 'None' },
    { value: 'true', label: 'Bordering' },
    { value: '2000', label: '2km' },
    { value: '5000', label: '5km' },
    { value: '10000', label: '10km' },
    { value: '15000', label: '15km' },
    { value: '20000', label: '20km' },
    { value: '30000', label: '30km' },
    { value: '40000', label: '40km' },
    { value: '50000', label: '50km' },
  ],
}

export const minPrice = {
  name: 'price__gte',
  label: 'Min Price',
  options: [
    { value: '', label: 'Any' },
    { value: 50000, label: '$50,000' },
    { value: 100000, label: '$100,000' },
    { value: 150000, label: '$150,000' },
    { value: 200000, label: '$200,000' },
    { value: 250000, label: '$250,000' },
    { value: 300000, label: '$300,000' },
    { value: 350000, label: '$350,000' },
    { value: 400000, label: '$400,000' },
    { value: 450000, label: '$450,000' },
    { value: 500000, label: '$500,000' },
    { value: 550000, label: '$550,000' },
    { value: 600000, label: '$600,000' },
    { value: 650000, label: '$650,000' },
    { value: 700000, label: '$700,000' },
    { value: 750000, label: '$750,000' },
    { value: 800000, label: '$800,000' },
    { value: 850000, label: '$850,000' },
    { value: 900000, label: '$900,000' },
    { value: 950000, label: '$950,000' },
    { value: 1000000, label: '$1,000,000' },
    { value: 1100000, label: '$1,100,000' },
    { value: 1200000, label: '$1,200,000' },
    { value: 1300000, label: '$1,300,000' },
    { value: 1400000, label: '$1,400,000' },
    { value: 1500000, label: '$1,500,000' },
    { value: 1600000, label: '$1,600,000' },
    { value: 1700000, label: '$1,700,000' },
    { value: 1800000, label: '$1,800,000' },
    { value: 1900000, label: '$1,900,000' },
    { value: 2000000, label: '$2,000,000' },
    { value: 2500000, label: '$2,500,000' },
    { value: 3000000, label: '$3,000,000' },
    { value: 3500000, label: '$3,500,000' },
    { value: 4000000, label: '$4,000,000' },
    { value: 4500000, label: '$4,500,000' },
    { value: 5000000, label: '$5,000,000' },
    { value: 6000000, label: '$6,000,000' },
    { value: 7000000, label: '$7,000,000' },
    { value: 8000000, label: '$8,000,000' },
    { value: 9000000, label: '$9,000,000' },
    { value: 10000000, label: '$10,000,000' },
  ],
}

export const maxPrice = {
  name: 'price__lte',
  label: 'Max price',
  options: [
    { value: '', label: 'Any' },
    { value: 50000, label: '$50,000' },
    { value: 100000, label: '$100,000' },
    { value: 150000, label: '$150,000' },
    { value: 200000, label: '$200,000' },
    { value: 250000, label: '$250,000' },
    { value: 300000, label: '$300,000' },
    { value: 350000, label: '$350,000' },
    { value: 400000, label: '$400,000' },
    { value: 450000, label: '$450,000' },
    { value: 500000, label: '$500,000' },
    { value: 550000, label: '$550,000' },
    { value: 600000, label: '$600,000' },
    { value: 650000, label: '$650,000' },
    { value: 700000, label: '$700,000' },
    { value: 750000, label: '$750,000' },
    { value: 800000, label: '$800,000' },
    { value: 850000, label: '$850,000' },
    { value: 900000, label: '$900,000' },
    { value: 950000, label: '$950,000' },
    { value: 1000000, label: '$1,000,000' },
    { value: 1100000, label: '$1,100,000' },
    { value: 1200000, label: '$1,200,000' },
    { value: 1300000, label: '$1,300,000' },
    { value: 1400000, label: '$1,400,000' },
    { value: 1500000, label: '$1,500,000' },
    { value: 1600000, label: '$1,600,000' },
    { value: 1700000, label: '$1,700,000' },
    { value: 1800000, label: '$1,800,000' },
    { value: 1900000, label: '$1,900,000' },
    { value: 2000000, label: '$2,000,000' },
    { value: 2500000, label: '$2,500,000' },
    { value: 3000000, label: '$3,000,000' },
    { value: 3500000, label: '$3,500,000' },
    { value: 4000000, label: '$4,000,000' },
    { value: 4500000, label: '$4,500,000' },
    { value: 5000000, label: '$5,000,000' },
    { value: 6000000, label: '$6,000,000' },
    { value: 7000000, label: '$7,000,000' },
    { value: 8000000, label: '$8,000,000' },
    { value: 9000000, label: '$9,000,000' },
    { value: 10000000, label: '$10,000,000' },
  ],
}

export const minRent = {
  name: 'rent__gte',
  label: 'Min Rent',
  options: [
    { value: '', label: 'Any' },
    { value: 50, label: '$50' },
    { value: 100, label: '$100' },
    { value: 150, label: '$150' },
    { value: 200, label: '$200' },
    { value: 250, label: '$250' },
    { value: 300, label: '$300' },
    { value: 350, label: '$350' },
    { value: 400, label: '$400' },
    { value: 450, label: '$450' },
    { value: 500, label: '$500' },
    { value: 550, label: '$550' },
    { value: 600, label: '$600' },
    { value: 650, label: '$650' },
    { value: 700, label: '$700' },
    { value: 750, label: '$750' },
    { value: 800, label: '$800' },
    { value: 850, label: '$850' },
    { value: 900, label: '$900' },
    { value: 950, label: '$950' },
    { value: 1000, label: '$1,000' },
    { value: 1100, label: '$1,100' },
    { value: 1200, label: '$1,200' },
    { value: 1300, label: '$1,300' },
    { value: 1400, label: '$1,400' },
    { value: 1500, label: '$1,500' },
    { value: 1600, label: '$1,600' },
    { value: 1700, label: '$1,700' },
    { value: 1800, label: '$1,800' },
    { value: 1900, label: '$1,900' },
    { value: 2000, label: '$2,000' },
    { value: 2500, label: '$2,500' },
    { value: 3000, label: '$3,000' },
    { value: 3500, label: '$3,500' },
    { value: 4000, label: '$4,000' },
    { value: 4500, label: '$4,500' },
    { value: 5000, label: '$5,000' },
    { value: 6000, label: '$6,000' },
    { value: 7000, label: '$7,000' },
    { value: 8000, label: '$8,000' },
    { value: 9000, label: '$9,000' },
    { value: 10000, label: '$10,000' },
  ],
}

export const maxRent = {
  name: 'rent__lte',
  label: 'Max Rent',
  options: [
    { value: '', label: 'Any' },
    { value: 50, label: '$50' },
    { value: 100, label: '$100' },
    { value: 150, label: '$150' },
    { value: 200, label: '$200' },
    { value: 250, label: '$250' },
    { value: 300, label: '$300' },
    { value: 350, label: '$350' },
    { value: 400, label: '$400' },
    { value: 450, label: '$450' },
    { value: 500, label: '$500' },
    { value: 550, label: '$550' },
    { value: 600, label: '$600' },
    { value: 650, label: '$650' },
    { value: 700, label: '$700' },
    { value: 750, label: '$750' },
    { value: 800, label: '$800' },
    { value: 850, label: '$850' },
    { value: 900, label: '$900' },
    { value: 950, label: '$950' },
    { value: 1000, label: '$1,000' },
    { value: 1100, label: '$1,100' },
    { value: 1200, label: '$1,200' },
    { value: 1300, label: '$1,300' },
    { value: 1400, label: '$1,400' },
    { value: 1500, label: '$1,500' },
    { value: 1600, label: '$1,600' },
    { value: 1700, label: '$1,700' },
    { value: 1800, label: '$1,800' },
    { value: 1900, label: '$1,900' },
    { value: 2000, label: '$2,000' },
    { value: 2500, label: '$2,500' },
    { value: 3000, label: '$3,000' },
    { value: 3500, label: '$3,500' },
    { value: 4000, label: '$4,000' },
    { value: 4500, label: '$4,500' },
    { value: 5000, label: '$5,000' },
    { value: 6000, label: '$6,000' },
    { value: 7000, label: '$7,000' },
    { value: 8000, label: '$8,000' },
    { value: 9000, label: '$9,000' },
    { value: 10000, label: '$10,000' },
  ],
}

export const minBedrooms = {
  name: 'bedrooms__gte',
  label: 'Min Bed',
  options: [
    { value: '', label: 'Beds + ' },
    { value: 1, label: '1 + Beds ' },
    { value: 2, label: '2 + Beds' },
    { value: 3, label: '3 + Beds' },
    { value: 4, label: '4 + Beds' },
    { value: 5, label: '5 + Beds' },
  ],
}

export const minBathrooms = {
  name: 'bathrooms__gte',
  label: 'Min Bath',
  options: [
    { value: '', label: 'Bath + ' },
    { value: 1, label: '1 + Baths ' },
    { value: 2, label: '2 + Baths' },
    { value: 3, label: '3 + Baths' },
    { value: 4, label: '4 + Baths' },
    { value: 5, label: '5 + Baths' },
  ],
}

export const minCarParks = {
  name: 'parking__gte',
  label: 'Car park',
  options: [
    { value: '', label: 'Car Parks ' },
    { value: 1, label: '1 + Car Parks ' },
    { value: 2, label: '2 + Car Parks' },
    { value: 3, label: '3 + Car Parks' },
    { value: 4, label: '4 + Car Parks' },
    { value: 5, label: '5 + Car Parks' },
  ],
}

export const minToilets = {
  name: 'toilets__gte',
  label: 'Min Toilet',
  options: [
    { value: '', label: 'Min Toilet' },
    { value: 1, label: '1 + Toilets ' },
    { value: 2, label: '2 + Toilets' },
    { value: 3, label: '3 + Toilets' },
    { value: 4, label: '4 + Toilets' },
    { value: 5, label: '5 + Toilets' },
  ],
}

export const study = {
  name: 'features',
  label: 'Feature',
  options: [
    { value: '', label: 'Any' },
    { value: 'Study', label: 'Study' },
  ],
}

export const minLandSize = {
  name: 'area__gte',
  label: 'Min Land Size',
  options: [
    { value: '', label: 'Any' },
    { value: 300, label: '300 sqm' },
    { value: 450, label: '450 sqm' },
    { value: 750, label: '750 sqm' },
    { value: 1000, label: '1,000 sqm' },
    { value: 2000, label: '2,000 sqm' },
    { value: 5000, label: '5,000 sqm' },
    { value: 10000, label: '10,000 sqm' },
  ],
}

export const maxLandSize = {
  name: 'area__lte',
  label: 'Max Land Size',
  options: [
    { value: '', label: 'Any' },
    { value: 300, label: '300 sqm' },
    { value: 450, label: '450 sqm' },
    { value: 750, label: '750 sqm' },
    { value: 1000, label: '1,000 sqm' },
    { value: 2000, label: '2,000 sqm' },
    { value: 5000, label: '5,000 sqm' },
    { value: 10000, label: '10,000 sqm' },
  ],
}

export const alertName = {
  name: 'descriptive_name',
  label: 'Alert name:',
}

export const alertFrequency = {
  name: 'frequency',
  radioLabel: 'Frequency:',
  options: [
    { value: 1, label: 'Daily' },
    { value: 7, label: 'Weekly' },
    { value: 14, label: 'Fortnightly' },
    { value: 30, label: 'Monthly' },
  ],
}

export const arrangeBy = {
  name: 'arrange_by',
  hasLabel: false,
  defaultValue: '-created',
  options: [
    {
      value: '-created',
      label: 'Most Recent',
      selected: true,
    },
    {
      value: 'created',
      label: 'Oldest listing',
      selected: false,
    },
    {
      value: 'price,rent',
      label: 'Cheapest',
      selected: false,
    },
    {
      value: '-price,-rent',
      label: 'Most Expensive',
      selected: false,
    },
    {
      value: '-updated',
      label: 'Last Updated',
      selected: false,
    },
  ],
}

export const soldArrangeBy = {
  name: 'arrange_by',
  hasLabel: false,
  defaultValue: '-soldDetails_date',
  options: [
    {
      value: '-soldDetails_date',
      label: 'Most Recent',
      selected: true,
    },
    {
      value: 'created',
      label: 'Oldest listing',
      selected: false,
    },
    {
      value: 'soldDetails_price',
      label: 'Cheapest',
      selected: false,
    },
    {
      value: '-soldDetails_price',
      label: 'Most Expensive',
      selected: false,
    },
    {
      value: '-updated',
      label: 'Last Updated',
      selected: false,
    },
  ],
}

